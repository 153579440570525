<template>
  <div class="form-group checkbox">
    <div class="ch-wrap">
      <label
        :class="[required ? 'required' : false]"
        v-if="label"
        :for="id || 'ch_' + uid"
      >
        <input
          :checked="this.checked"
          :required="required"
          v-bind="$attrs"
          v-on="listeners"
          :type="cRadio"
          class="form-control"
          :class="_class"
          :value="value"
          :id="id || 'ch_' + uid"
          @input="onInput($event)"
          @change="onChange($event)"
        />
        <span class="label box">
          {{ label }}
        </span>
      </label>
    </div>
    <transition name="fade">
      <small
        v-if="invalidFeedback || description"
        :id="'area-' + id"
        class="form-text text-muted"
        :class="[invalidFeedback ? 'danger-color' : false]"
      >
        {{ invalidFeedback || description }}
      </small>
    </transition>
  </div>
</template>

<script>
import {inputCheckboxProps as props} from "./input-props";

export default {
  name: "ACheckBox",
  props: {
    ...props,
    label: String,
    _class: String,
    id: String,
    radio: Boolean,
    description: String,
    value: [String, Number, Boolean]
  },
  data() {
    return {
      checkedState: this.checked
    };
  },
  computed: {
    cRadio() {
      return this.radio ? "radio" : "checkbox";
    },
    listeners() {
      const {input, change, ...listeners} = this.$listeners; // eslint-disable-line no-unused-vars
      return listeners;
    }
  },
  methods: {
    onInput(e) {
      this.state = e.target.value;
      this.checkedState = e.target.checked;
      this.$emit("input", this.state, e);
      if (this.lazy === true) {
        return;
      }
      clearTimeout(this.syncTimeout);
      this.syncTimeout = setTimeout(
        () => {
          this.$emit("update:checked", this.checkedState, e);
        },
        this.lazy !== false ? this.lazy : 0
      );
    },
    onChange(e) {
      this.state = e.target.value;
      this.checkedState = e.target.checked;
      this.$emit("change", this.state, e);
      this.$emit("update:checked", this.checkedState, e);
    }
  }
};
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: var(--th-inputs-margin-buttom);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .box:before {
        background: var(--th-blue);
      }

      & ~ .box:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  label {
    display: block;
  }

  .box {
    display: flex;
    user-select: none;

    &:before {
      width: 20px;
      height: 20px;
      content: "";
      border: 2px solid var(--th-blue);
      transition: all 200ms;
      margin-right: 10px;
      border-radius: 3px;
      position: relative;
      z-index: 1;
    }

    &:after {
      opacity: 0;
      transform: scale(0);
      z-index: 3;
      position: absolute;
      width: 20px;
      height: 20px;
      content: "";
      background: url('~@/assets/icons/svg/succ.svg') center center/ 70% no-repeat;
      transition: 150ms all 150ms;
      margin-right: 10px;
      border-radius: 3px;
    }
  }

  .checkbox {
    width: 100%;
  }
}
</style>
